<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="job">
      <h1 class="title-md">
        {{ job }}
      </h1>
    </div>
    <div class="d-flex justify-content-center p-4">
      <div class="job-content">
        <div>
          <h3>Brief summary</h3>
          <p>{{ content.summary }}</p>
        </div>

        <div class="my-3">
          <h3>Responsibilities</h3>
          <ul>
            <li v-for="(r, inde) in content.responsibilities" :key="inde">
              {{ r }}
            </li>
          </ul>
        </div>

        <div>
          <h3>Requirements</h3>
          <ul>
            <li v-for="(r, inde) in content.requirements" :key="inde">
              {{ r }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mb-5">
      <div class="d-flex justify-content-center">
        <button class="btn-md mr-1 border-btn" @click="goBack">Back</button>
        <b-button
          variant="primary"
          class="btn-md"
          :href="`mailto:${emailAddress}?subject=${applySubject}`"
        >
          Apply
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { job: String, id: Number },
  data() {
    return {
      emailAddress: "weiying@glogc.com",
      applySubject: `Application for ${this.job} position`,
      content: {
        title: "Job title",
        summary:
          "Mi tincidunt elit, id quisque ligula ac diam, amet. Vel etiam suspendisse morbi eleifend faucibus eget vestibulum felis. Dictum quis montes, sit sit. Tellus aliquam enim urna, etiam. Mauris posuere vulputate arcu amet, vitae nisi, tellus tincidunt. At feugiat sapien varius id. \nEget quis mi enim, leo lacinia pharetra, semper. Eget in volutpat mollis at volutpat lectus velit, sed auctor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam imperdiet. Ipsum molestie aliquet sodales id est ac volutpat.",
        responsibilities: [
          "Eget quis mi enim l",
          "eo lacinia pharetra, semper. Eget in volu",
          "tpat mollis at volutpat lectus velit, sed auc",
          "tor. Porttitor fames arcu quis fusce augue enim. Quis at habitant diam at. Suscipit tristique risus, at donec. In turpis vel et quam im",
          "perdiet. Ipsum molestie aliquet s",
          "odales id est ac volutpat.",
        ],
        requirements: [
          "Morbi sed imperdiet in ipsum, a",
          "dipiscing elit dui lectus. Tellus id",
          "scelerisque est ultricies ultricies. Duis es",
          "t sit sed leo nisl, blandit eli",
          "t sagittis. Quisque tristique consequa",
          "t quam sed. Nisl at scelerisque amet nulla",
          "purus habitasse.",
        ],
      },
    };
  },
  methods: {
    goBack() {
      this.$router.back(); // or use this.$router.go(-1) to go back in history
    },
  },
};
</script>
